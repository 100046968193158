@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Playfair+Display:wght@400;600&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body,
#root,
.app {
  height: 100%;
  background-color: #010026;
  @apply text-white;
}

.slogan::before{
  transform: scaleX(2) !important;
}

::-webkit-scrollbar {
  width: 0px;
}


@media only screen and (min-width: 1700px) {
  .slogan::before{
    left:110px !important;
  }
}

@media only screen and (min-width: 2000px) {
  .slogan::before{
    left:142px !important;
  }
}

@media only screen and (min-width: 2200px) {
  .slogan::before{
    left:222px !important;
  }
}

