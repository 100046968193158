body.active-modal {
    overflow-y: hidden;
}

.btn-modal {
    padding: 5px;
    display: block;
    margin: 50px auto;
    font-size: 18px;
}

.modal, .overlay {
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
}

.modal{
    box-shadow: 0px 2px 5px rgba(0,0,0,0.1);
}

.overlay {
    background: rgba(49,49,49,0.8);
    filter: blur(20px);
    pointer-events: none;
    user-select: none;
}
.modal-content {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    line-height: 1.4;
    padding: 14px 28px;
    border-radius: 3px;
    /* max-width: 600px;
    min-width: 500px; */
}

.close-modal {
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 5px 7px;
}